import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import hu from 'date-fns/locale/hu';
import React, { ReactPortal, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createPortal } from 'react-dom';
import { styled, theme } from '../../config/Theme';
import CreativeCampaignType, { CreativeCampaignTypeNames } from '../../enums/CreativeCampaignType';
import Platform, { PlatformNames } from '../../enums/Platform';
import useAPI, { API } from '../../hooks/useAPI';
import useModal from '../../hooks/useModal';
import Creative from '../../models/Creative';
import CreativeVariation from '../../models/CreativeVariation';
import Daterange from '../../models/Daterange';
import Toaster from '../../utils/Toaster';
import Badge from '../Badge/Badge';
import DateBadge from '../Badge/DateBadge';
import InnerBadge from '../Badge/InnerBadge';
import SwitchBadge from '../Badge/SwitchBadge';
import IconButton from '../Button/IconButton';
import MainButton from '../Button/MainButton';
import DatePicker from '../Cards/DatePicker';
import { ModalOverlay, ModalWrapper } from '../Cards/Modal';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Flex from '../Spacing/Flex';
import { Muted, Span, SpanStyle } from '../Typography';
import CreativeModalDateBadge from '../Badge/CreativeModalDateBadge';
import { CreativeDate } from './CreativeModal';
import { GroupedRadio } from '../Form/GroupedRadio';
import { getDaysProposed, getVariationRange } from '../../utils/VariationRange';
import FormInput from '../Form/FormInput';
import { FormButton, FormItem } from '../Form/Form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import CreativeType from '../../enums/CreativeType';
import { ErrorMsg } from '../Form/Form';
import moment, { isMoment } from 'moment';

const ModalStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.color.shadow};
  width: 45%;
  max-height: 50%;
  min-height: 30%;
  position: fixed;
  z-index: 1060;
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.gray1};
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 2000px) {
    width: 40%;
  }

  @media only screen and (max-width: 1900px) {
    width: 45%;
  }

  @media only screen and (max-width: 1700px) {
    width: 50%;
  }
`;

const ModalHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 ${(props) => props.theme.space.lsmall};
  box-sizing: border-box;
  height: ${(props) => props.theme.space.large};
  min-height: ${(props) => props.theme.space.large};
`;

const ModalBody = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.lsmall};
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - ${(props) => props.theme.space.large});
  form {
    width: 100%;
  }
`;

export const Widget = styled(Flex)`
  flex-direction: column;
  width: max-content;
  margin-right: ${(props) => props.theme.space.xsmall};
`;

export const Label = styled(Muted)`
  margin-bottom: ${(props) => props.theme.space.half};
`;

export const ParamsLabel = styled(Muted)`
  margin-bottom: ${(props) => props.theme.space.half};
  margin-top: ${(props) => props.theme.space.tiny};
`;

export const EditDayBadge = styled(Badge)`
  background-color: ${(props) => props.theme.color.white};
  width: max-content;
  margin-right: ${(props) => props.theme.space.half};
  padding: ${(props) => props.theme.space.half} ${(props) => props.theme.space.quarter};

  :last-child {
    margin-right: 0;
  }

  ${Span}.duration {
    margin-left: ${(props) => props.theme.space.quarter};
    color: ${(props) => props.theme.color.gray3};
  }
`;

const ArticleInput = styled.input`
  position: relative;
  height: auto;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.white};

  &:focus {
    outline: none;
  }
`;

export const DayInput = styled.input`
  ${SpanStyle}
  width: ${(props) => props.theme.space.tiny};
  border: 0px solid;
  padding: 0;
  background-color: transparent;
  height: min-content;
  outline: 0px;
`;

export type PrArticleModalProps = {
  updateCreative: (creative: Creative, save?: boolean) => void;
  isCreating?: boolean;
  creative?: Creative;
  isOpen: boolean;
  toggle: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const DaterangeSchema = z.object({
  id: z.string().optional(),
  start: z.custom((data) => moment(data as string).isValid()).optional(),
  end: z.custom((data) => moment(data as string).isValid()).optional(),
});

const schema = z.object({
  platform: z.nativeEnum(Platform, { required_error: 'Kötelező kiválasztani' }),
  articleTitle: z.string().nullable(),
  dateRanges: z.array(DaterangeSchema).nonempty({ message: 'Kötelező kiválasztani' }),
});

type CreativeFormValues = z.infer<typeof schema>;

const PrArticleModal = (props: PrArticleModalProps): ReactPortal | null => {
  const [totalDate, setTotalDate] = useState<CreativeDate | undefined>();

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isPickerOpen, togglePicker] = useModal();

  const [variation, setVariation] = useState<CreativeVariation>();

  const [platform, setPlatform] = useState<Platform | undefined>(props.creative?.platform);

  const [variationInPicker, setVariationInPicker] = useState<CreativeVariation>();

  const [, putVariation] = useAPI<CreativeVariation>({ method: 'PUT' }, { manual: true });

  const [putCreativeResponse, putCreative] = useAPI<Creative>({ method: 'PUT' }, { manual: true });

  const [, getVariation] = useAPI<CreativeVariation>({ method: 'GET' }, { manual: true });

  const [getCreativeResponse, getCreative] = useAPI<Creative>({ method: 'GET' }, { manual: true });
  const [, postCreative] = useAPI<Creative>({ url: `/creatives`, method: 'POST' }, { manual: true });

  const form = useForm<CreativeFormValues>({
    defaultValues: {
      platform: props.creative?.platform ?? undefined,
      articleTitle: props.creative?.variations?.[0]?.articleTitle ?? undefined,
      dateRanges: variation?.dateRanges || props.creative?.variations?.[0]?.dateRanges || [],
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const {
    formState: { errors, isValid },
  } = form;

  const updateCreative = async (creative: Creative) => {
    if (isLoading) return;
    setLoading(true);
    const result = await putCreative({ url: `/creatives/${creative.id}`, data: creative });

    if (putCreativeResponse.error || !result.data) {
      Toaster.error('Sikertelen módosítás!');
      return;
    }
    setLoading(false);

    props.updateCreative(result.data);
  };

  const openPicker = (variation: CreativeVariation) => {
    setVariationInPicker({ ...variation });
    togglePicker(true);
  };

  const onPickerFinish = (ranges: Daterange[], canceled?: boolean) => {
    togglePicker(false);

    if (canceled) {
      setVariationInPicker(undefined);
      return;
    }

    const variation = variationInPicker;
    if (!variation) return;

    variation.dateRanges = ranges.map((r) => new Daterange(undefined, r.start, r.end));
    updateVariation(variation, null, true);
  };

  const onTitleEdit = async (title: string) => {
    if (!variation) return;
    variation.articleTitle = title;
    await updateVariation(variation);
  };

  const handleSave = async (data: CreativeFormValues) => {
    if (!variation) return;
    const updatedVariation = { ...variation, articleTitle: data.articleTitle as string | undefined };

    if (props.isCreating) {
      const { data: cr } = await postCreative({ data: { ...props.creative, platform: data.platform } });
      const v = await getVariation({ url: `/creatives/${cr.id}/variations/article` });
      setVariation(v.data);
      v.data.dateRanges = variation.dateRanges?.map((r) => new Daterange(undefined, r.start, r.end));
      v.data.articleTitle = data.articleTitle as string | undefined;
      updateVariation(v.data, cr);
      cr.variations = [updatedVariation];
      props.updateCreative(cr, true);
    } else {
      await updateVariation(updatedVariation);
      if (props.creative)
        props.updateCreative({ ...props.creative, platform: data.platform, variations: [updatedVariation] }, true);
    }
    props.toggle();
  };

  const updateVariation = async (
    variation: CreativeVariation,
    savedCreative: Creative | null = null,
    update: boolean = false
  ) => {
    form.setValue('dateRanges', variation.dateRanges || ([] as any));
    if ((props.isCreating && !savedCreative) || update) {
      delete variation.creative;
      setVariation(variation);
      const { start, end } = getVariationRange(variation);
      const newCreative: Creative = {
        ...props.creative,
        platform,
        variations: [variation],
        start,
        end,
        daysProposed: getDaysProposed(props.creative, variation),
      };
      props.updateCreative(newCreative);
      return;
    }

    const creative = savedCreative || props.creative;
    delete variation.creative;
    const res = await putVariation({
      url: `/creatives/${creative?.id}/variations/${variation.id}`,
      data: variation,
    });

    if (!res || !res.data) return;

    const newVariations = [...(creative?.variations?.filter((v) => v.id !== res.data.id) ?? []), res.data];
    const updatedCreativeResponse = await getCreative({ url: `/creatives/${creative?.id}` });

    const newCreative = {
      ...updatedCreativeResponse.data,
      variations: newVariations,
      platform,
      start: newVariations.map((v) => v.start).sort()[0],
      end: newVariations.map((v) => v.end).sort()[0],
    };

    props.updateCreative(newCreative);
  };

  const calculateCreativeTimespan = (
    start: Date | undefined,
    end: Date | undefined,
    daysProposed: number | undefined
  ): void => {
    if (!start || !end) {
      setTotalDate({
        months: 0,
        weeks: 0,
        days: 0,
      });

      return;
    }

    let months: number, weeks: number, days: number;
    const difference = new Date(end).getTime() - new Date(start).getTime();
    const totalDaysProposed = daysProposed ? daysProposed : Math.ceil(difference / (1000 * 3600 * 24));

    months = Math.floor(totalDaysProposed / 30);
    weeks = Math.floor((totalDaysProposed - months * 30) / 7);
    days = totalDaysProposed - months * 30 - weeks * 7;

    setTotalDate({
      months,
      weeks,
      days,
    });
  };

  const getPRVariation = (creative: Creative | undefined) => {
    if (!creative || !creative.id) return;
    getVariation({ url: `/creatives/${creative.id}/variations/article` }).then((res) => {
      if (!res || !res?.data) return;
      // if (variation?.dateRanges?.length !== 0) {
      //   res.data.dateRanges = [...(res.data?.dateRanges || []), ...(variation?.dateRanges || [])];
      // }
      setVariation(res.data);
    });
  };

  const handlePlatformSelect = (value: Platform) => {
    setPlatform(value);
    // updateCreative({ ...props.creative, platform: value });
  };

  useEffect(() => {
    // We update the Timespan(Időtartam) when the creative changes
    calculateCreativeTimespan(props.creative?.start, props.creative?.end, props.creative?.daysProposed);
  }, [props.creative]);

  useEffect(() => {
    getPRVariation(props.creative);
  }, []);

  registerLocale('hu', hu);

  return props.isOpen
    ? createPortal(
        <ModalWrapper>
          <ModalOverlay onClick={props.toggle} />
          <ModalStyled className='modal'>
            <ModalHeader>
              <Span color={theme.color.gray3}>PR cikk hozzáadása</Span>
              <IconButton
                size='lg'
                icon={faTimesCircle}
                color={theme.color.black}
                hoverColor={theme.color.gray3}
                onClick={props.toggle}
              />
            </ModalHeader>

            <ModalBody>
              <form
                onSubmit={form.handleSubmit((data) => {
                  handleSave(data);
                })}
              >
                <Flex row justify='space-between' style={{ marginBottom: theme.space.tiny }}>
                  <Flex row justify='flex-end'>
                    <Widget>
                      <Label>Kampány</Label>
                      <SwitchBadge width='100%'>
                        <InnerBadge bgColor={theme.color.gray2} style={{ cursor: 'default' }}>
                          <Span>
                            {CreativeCampaignTypeNames.get(props.creative?.campaign ?? CreativeCampaignType.TIME)}
                          </Span>
                        </InnerBadge>
                      </SwitchBadge>
                    </Widget>

                    <div>
                      <Widget>
                        <Label>Dátum</Label>
                        <DateBadge
                          start={props.creative?.start}
                          end={props.creative?.end}
                          wide
                          onClick={() => openPicker(variation ?? {})}
                        />
                      </Widget>
                      <ErrorMsg>{errors.dateRanges?.message}</ErrorMsg>
                    </div>
                    <Flex column justify='flex-start'>
                      <Flex column justify='flex-start'>
                        <Label>Időtartam</Label>
                        <Flex column justify='flex-start' width='auto'>
                          <CreativeModalDateBadge
                            months={totalDate?.months ?? 0}
                            weeks={totalDate?.weeks ?? 0}
                            days={totalDate?.days ?? 0}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex>
                  <FormControl style={{ marginBottom: '1rem' }}>
                    <Label>
                      <Flex row justify='space-between' width='100%'>
                        Platform
                      </Flex>
                    </Label>
                    <GroupedRadio>
                      <Controller
                        name='platform'
                        control={form.control}
                        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                          <RadioGroup
                            row
                            // {...formPlatform}
                            onChange={(e) => {
                              onChange(e);
                              // handlePlatformSelect(e.target.value as Platform);
                            }}
                            value={value ?? ' '}
                          >
                            {Object.values(Platform).map((value, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={value}
                                  sx={{
                                    '& .MuiTypography-root': {
                                      fontSize: 14,
                                      lineHeight: '14px',
                                    },
                                  }}
                                  control={
                                    <Radio
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: 16,
                                        },
                                      }}
                                    />
                                  }
                                  label={PlatformNames.get(value)}
                                />
                              );
                            })}
                          </RadioGroup>
                        )}
                      />
                    </GroupedRadio>
                  </FormControl>
                </Flex>

                <div style={{ width: '100%' }}>
                  <FormItem>
                    <FormInput
                      errorMessage={errors.articleTitle?.message}
                      label='PR cikk címe'
                      {...form.register('articleTitle')}
                    ></FormInput>
                  </FormItem>
                </div>
                <FormButton>
                  <MainButton
                    variation='create'
                    disabled={!isValid && (!variation || props.creative?.variations?.[0]?.dateRanges?.length === 0)}
                  >
                    {props.isCreating ? 'Létrehozás' : 'Mentés'}
                  </MainButton>
                </FormButton>

                {isPickerOpen && <DatePicker onFinish={onPickerFinish} ranges={variationInPicker?.dateRanges ?? []} />}
              </form>
            </ModalBody>
          </ModalStyled>
        </ModalWrapper>,
        document.body
      )
    : null;
};

export default PrArticleModal;
